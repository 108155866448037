import * as Sentry from '@sentry/nextjs'
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { isPreviewEnv } from 'src/helpers'

import LoginButtons, { AuthProvider } from './LoginButtons'

const FirebaseAuth = () => {
  const [error, setError] = useState('')
  const auth = getAuth()

  useEffect(() => {
    getRedirectResult(auth).catch(error => {
      setError(error.message || 'An error occured')
      Sentry.captureException(error)
    })
  }, [auth])

  const handleAuthClick = useCallback(
    (authProvider: AuthProvider) => {
      auth.useDeviceLanguage()
      let provider: GoogleAuthProvider | OAuthProvider | undefined
      if (authProvider === AuthProvider.Google) {
        provider = new GoogleAuthProvider()
        provider.setCustomParameters({
          prompt: 'select_account',
        })
      }
      if (authProvider === AuthProvider.Microsoft) {
        provider = new OAuthProvider('microsoft.com')
        provider.addScope('email')
        provider.addScope('openid')
      }
      if (!provider) return

      if (isPreviewEnv() && navigator.userAgent.includes('Safari')) {
        // Safari cross-domain restrictions prevents Firebase redirect auth flow
        // to work. See <https://github.com/firebase/firebase-js-sdk/issues/6716>.
        // To mitigate with we use the popup flow which doesn't have this issue,
        // for Safari in preview environments.
        signInWithPopup(getAuth(), provider)
      } else {
        signInWithRedirect(getAuth(), provider)
      }
    },
    [auth]
  )

  return (
    <div className='flex flex-col items-center'>
      {error && (
        <div className='flex mt-6 justify-center'>
          <p className='text-critical-action'>{error}</p>
        </div>
      )}
      <LoginButtons onClick={handleAuthClick} />
    </div>
  )
}

export default FirebaseAuth
